@font-face {
  font-family: 'minecraftmedium';
  src: url('minecraft-webfont.woff2') format('woff2'),
       url('minecraft-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
html, body {
  height: 100%;
}
body {
  margin: 0;
  background: #130025;
  font-family: 'minecraftmedium';
}
#root { 
  height: 100%;
}
.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
}
.flex-item {
  margin: 10px;
  text-align: center;
}
p {
  color: #A35FE1;
}
a {
  color: #A35FE1;
  text-decoration: none;
  transition: color 0.3s linear;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
}
a:hover {
  color: #cfa9f3;
}
a:visited {
  color: #A35FE1;
  text-decoration: none;
}